<template>
    <div class="d-flex flex-wrap flex-stack my-5">
        <router-link to="/module/out-question/category" class="btn btn-primary align-self-center">
            <span class="svg-icon svg-icon-2">
                <inline-svg src="/media/icons/duotune/arrows/arr021.svg" />
            </span>
            {{ $t("pages.module.outQuestion.category.title") }}
        </router-link>
    </div>
    <custom-table
        :title="sprintf($t('pages.module.outQuestion.titlePattern'), [category.defaultLanguageTranslate.title ?? ''])"
        :subTitle="$t('pages.module.outQuestion.subTitle')"
        selectableRows
        rowKey="id"
        :items="table.data"
        :loading="table.loading"
        :pagination="table.pagination"
        :columns="fields"
        :actions="actions"
        @action="handleClickAction"
        @selectableRows="handleSelectedRow"
        @changeTable="handleTableChange">
        <template v-slot:category="{ row: record }">
            {{ resolveDatum(record.category.translations, $root.defaultLanguage.id, 'language_id').title }}
        </template>
        <template v-slot:show_download="{ row: record }">
            <span :class="record.show_download ? 'badge-light-success': 'badge-light-danger'" class="badge">{{ record.show_download ? $t('common.yes') : $t('common.no') }}</span>
        </template>
        <template v-slot:status="{ row: record }">
            <span :class="record.active ? 'badge-light-success': 'badge-light-danger'" class="badge">{{ record.active ? $t('common.active') : $t('common.passive') }}</span>
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <el-tooltip :content="$t('pages.module.outQuestion.showDocument')" placement="top" v-if="!record.has_children">
                    <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" v-on:click="showDocument(record)">
                        <span class="svg-icon svg-icon-3">
                            <inline-svg src="/media/icons/duotune/general/gen004.svg" />
                        </span>
                    </a>
                </el-tooltip>
                <a v-on:click="fetchOutQuestion(record)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                    <span class="svg-icon svg-icon-3">
                        <inline-svg src="/media/icons/duotune/art/art005.svg"/>
                    </span>
                </a>
                <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord([record.id])">
                    <template #reference>
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>
            </div>
        </template>
    </custom-table>
    <div class="modal fade" id="kt_modal_add_out_question" ref="addOutQuestionModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_add_customer_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg"/>
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmit()" :model="form.data" ref="outQuestionForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <!--<div class="fv-row mb-7" v-show="!form.updateStatus">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.outQuestion.cols.category') }}</label>
                                <el-form-item prop="category_id" :rules="$validation.getMessage(['required'])">
                                    <el-input type="hidden" v-model="form.data.category_id"></el-input>
                                    <el-select-tree :value="form.data.category_id" @input="form.data.category_id = $event" :data="treeCategories" :placeholder="$t('common.chooseSelect')" :multiple="false"/>
                                </el-form-item>
                            </div>-->

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.outQuestion.cols.title') }}</label>
                                <el-form-item prop="title" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.data.title" type="text"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7" v-if="!form.updateStatus">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.outQuestion.upload.title') }}</label>
                                <el-form-item prop="upload_id">
                                    <el-upload :file-list="form.file.list" :accept="$root.acceptFileSupported('document', '.')" class="upload-file" :on-change="handleChangeFile" :on-remove="handleChangeFile" :auto-upload="false" drag :multiple="false" :limit="1">
                                        <el-icon class="el-icon--upload">
                                             <span class="svg-icon">
                                                 <inline-svg src="/media/icons/duotune/files/fil022.svg" />
                                             </span>
                                        </el-icon>
                                        <div class="el-upload__text">{{ $t('pages.module.outQuestion.upload.dragFile')}}</div>
                                    </el-upload>
                                </el-form-item>
                            </div>

                            <div class="row">
                                <div class="col-md-6 fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">
                                        {{ $t('pages.module.outQuestion.cols.showDownload') }}
                                        <el-tooltip class="item ms-1" popper-class="max-w-300px" effect="dark" :content="$t('pages.module.outQuestion.informationBoxes.showDownload')" placement="top">
                                            <span class="svg-icon svg-icon-1">
                                                <inline-svg src="/media/icons/duotune/general/gen046.svg" />
                                            </span>
                                        </el-tooltip>
                                    </label>
                                    <el-form-item prop="show_download">
                                        <el-radio-group v-model="form.data.show_download">
                                            <el-radio-button :label="true">{{ $t("common.yes") }}</el-radio-button>
                                            <el-radio-button :label="false">{{ $t("common.no") }}</el-radio-button>
                                        </el-radio-group>
                                    </el-form-item>
                                </div>
                                <div class="col-md-6 fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">
                                        {{ $t('pages.module.outQuestion.cols.showToolbar') }}
                                        <el-tooltip class="item ms-1" popper-class="max-w-300px" effect="dark" :content="$t('pages.module.outQuestion.informationBoxes.showToolbar')" placement="top">
                                            <span class="svg-icon svg-icon-1">
                                                <inline-svg src="/media/icons/duotune/general/gen046.svg" />
                                            </span>
                                        </el-tooltip>
                                    </label>
                                    <el-form-item prop="show_toolbar">
                                        <el-radio-group v-model="form.data.show_toolbar">
                                            <el-radio-button :label="true">{{ $t("common.yes") }}</el-radio-button>
                                            <el-radio-button :label="false">{{ $t("common.no") }}</el-radio-button>
                                        </el-radio-group>
                                    </el-form-item>
                                </div>
                                <div class="col-md-6 fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.outQuestion.cols.sort') }}</label>
                                    <el-form-item prop="sort" :rules="$validation.getMessage(['required'])">
                                        <el-input-number v-model="form.data.sort" :min="1" />
                                    </el-form-item>
                                </div>
                                <div class="col-md-6 fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.outQuestion.cols.status') }}</label>
                                    <el-form-item prop="status">
                                        <el-radio-group v-model="form.data.active">
                                            <el-radio-button :label="true">{{ $t("common.active") }}</el-radio-button>
                                            <el-radio-button :label="false">{{ $t("common.passive") }}</el-radio-button>
                                        </el-radio-group>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="form.loading">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{ $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";
import ElSelectTree from "@/components/ElSelectTree.vue";

export default {
    name: "_id",
    components: {
        CustomTable,
        ElSelectTree
    },
    data() {
        return {
            fields: [
                {
                    name: this.$t("pages.module.outQuestion.cols.id"),
                    key: "id",
                    class: ""
                },
                {
                    name: this.$t("pages.module.outQuestion.cols.title"),
                    key: "title"
                },
                {
                    name: this.$t("pages.module.outQuestion.cols.category"),
                    scopedSlots: {customRender: "category"}
                },
                {
                    name: this.$t("pages.module.outQuestion.showDownload"),
                    scopedSlots: {customRender: "show_download"}
                },
                {
                    name: this.$t("pages.module.outQuestion.cols.sort"),
                    key: "sort",
                    class: ""
                },
                {
                    name: this.$t("pages.module.outQuestion.cols.status"),
                    scopedSlots: {customRender: "status"}
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "new",
                    icon: "bi-plus-lg",
                    label: "btn.add"
                },
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                },
                {
                    name: "delete",
                    icon: "bi-trash",
                    label: "btn.delete"
                }
            ],
            form: {
                file: {
                    list: [],
                    uploadList: []
                },
                title: '',
                loading: false,
                updateStatus: false,
                data: {}
            },
            /*treeCategories: [],*/
            category: {
                defaultLanguageTranslate: {}
            },
            selectedRowKeys: [],
        }
    },
    computed: {
        table() {
            return this.$store.state.module.outQuestion.table;
        },
        categoryID() {
            return this.$route.params.id;
        },
    },
    created() {
        if (!this.categoryID || !(this.categoryID > 0)) {
            this.$router.push({
                path: "/module/out-question/category"
            });
        }
    },
    mounted() {
        if (this.categoryID && this.categoryID > 0) {
            this.$store.dispatch('module/outQuestion/get', {
                page: {},
                filterData: {category_id: this.categoryID}
            });
            /*this.loadTreeCategories();*/
            this.loadCategory();
        }
    },
    methods: {
        newOutQuestion() {
            this.form.updateStatus = false;
            this.form.file = {
                list: [],
                uploadList: []
            };

            this.form.data = {
                category_id: this.categoryID,
                sort: 1,
                show_download: true,
                show_toolbar: true,
                active: true
            };

            this.form.title = this.$t("pages.module.outQuestion.newOutQuestion");
            this.showModal(this.$refs.addOutQuestionModal);
        },
        fetchOutQuestion(record) {
            this.form.updateStatus = true;
            this.form.title = this.$t("pages.module.outQuestion.editOutQuestion");
            this.axios.get(this.endpoints['module_out_question'] + '/' + record.id).then(response => {
                let data = response.data.data;

                this.form.data = data;

                this.showModal(this.$refs.addOutQuestionModal);
            });
        },
        /*loadTreeCategories() {
            this.axios.get(this.endpoints['module_out_question_category'] + '/tree').then((response) => {
                this.treeCategories = response.data.data;
            });
        },*/
        showDocument(record) {
            this.axios.get(this.endpoints['module_out_question'] + '/' + record.id).then(response => {
                if(response.status){
                    let data = response.data.data;
                    window.open(data.upload.public_url, '_blank');
                }
            });
        },
        loadCategory(){
            this.axios.get(this.endpoints['module_out_question_category'] + '/' + this.categoryID).then((response) => {
                let data = response.data.data;

                if(data.has_children) {
                    this.$router.push({
                        path: "/module/out-question/category"
                    });
                }

                this.category = data;
                this.category.defaultLanguageTranslate = this.resolveDatum(data.translations, this.$root.defaultLanguage.id, 'language_id');
            });
        },
        onSubmit() {
            this.$refs.outQuestionForm.validate((valid) => {
                if (valid) {
                    if(this.form.updateStatus) {
                        this.submitForm();
                    } else {
                        this.submitFormWithUpload();
                    }
                } else {
                    return false;
                }
            });
        },
        submitFormWithUpload() {
            if(!(this.form.file.uploadList.length > 0)){
                this.$notify({
                    type: 'warning',
                    title: this.$t("messages.warning"),
                    message: this.$t("pages.module.outQuestion.warnings.requiredDocument"),
                })
                return false;
            }

            this.form.loading = true;

            const formData = new FormData();

            this.form.file.uploadList.forEach(file => {
                formData.append("file[]", file.raw);
            });

            this.axios.post(this.endpoints["file"], formData).then(response => {
                this.onResponse(response.data, () => {
                    let data = response.data.data;
                    this.form.data.document_id = data[0].id;
                    this.submitForm();
                }, () => {}, false);
            }).catch(error => {
                this.onResponseFailure(error.response.data, () => {
                    this.form.loading = false;
                });
            });
        },
        submitForm() {
            this.form.loading = true;

            if (this.form.data.id) {
                this.axios.put(this.endpoints['module_out_question'] + '/' + this.form.data.id, this.form.data).then(response => {
                    this.onResponse(response.data, () => {
                        this.refreshTable();
                        this.hideModal(this.$refs.addOutQuestionModal);
                    }, () => {
                        this.form.loading = false;
                    });
                }).catch(error => {
                    this.onResponseFailure(error.response.data, () => {
                        this.form.loading = false;
                    });
                });
            } else {
                this.axios.post(this.endpoints['module_out_question'], this.form.data).then(response => {
                    this.onResponse(response.data, () => {
                        this.refreshTable();
                        this.hideModal(this.$refs.addOutQuestionModal);
                    }, () => {
                        this.form.loading = false;
                    });
                }).catch(error => {
                    this.onResponseFailure(error.response.data, () => {
                        this.form.loading = false;
                    });
                });
            }
        },
        deleteRecord(id) {
            this.$store.dispatch("module/outQuestion/delete", {
                id: id
            }).then((successDeleted) => {
                this.selectedRowKeys = this.selectedRowKeys.filter(x => !successDeleted.includes(x));
            });
        },
        refreshTable(){
            this.$store.dispatch("module/outQuestion/refresh");
        },
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.refreshTable();
                    break;

                case "new":
                    this.newOutQuestion();
                    break;

                case "delete":
                    this.deleteRecord(this.selectedRowKeys.flat());
                    break;

                default:
                    break;
            }
        },
        handleTableChange(pagination, filterData) {
            this.$store.dispatch("module/outQuestion/get", {
                page: pagination,
                filterData: Object.assign({
                    category_id: this.categoryID
                }, filterData)
            });
        },
        handleSelectedRow(record) {
            this.selectedRowKeys = record;
        },
        handleChangeFile(file, fileList){
            this.form.file.list = fileList;
            this.form.file.uploadList = fileList;
        },
    }
}
</script>

<style>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 100%;
}

.upload-file .el-upload {
    width: 100%;
}

.upload-file .el-upload .el-upload-dragger{
    width: 100%;
}
.svg-icon svg {
    height: 1em;
    width: 1em;
}
</style>